import React from 'react';
import { ChangePassword } from '@components';

type TProps = {
    token: string;
};

const CrearContrasena = (props: TProps) => {
    return <ChangePassword token={props.token} />;
};

export default CrearContrasena;
